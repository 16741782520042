/**
 * @name: 档口管理-档口信息管理接口文件
 * @author: itmobai
 * @date: 2023-08-04 17:46
 * @description： 档口管理-档口信息管理接口文件
 * @update: 2023-08-04 17:46
 */
import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import { IGate, IGateParams } from "./types";

/**
 * 查询档口列表
 * @param params 查询参数
 * @returns
 */
export const gateQueryApi = (params: IGateParams) => get<IPageRes<IGate[]>>("/admin/gate/query", params)
/**
 * 创建档口
 * @param data
 * @returns
 */
export const gateCreateApi = (data: IGate) => postJ("/admin/gate/create", data)
/**
 * 编辑档口
 * @param data
 * @returns
 */
export const gateModifyApi = (data: IGate) => postJ("/admin/gate/modify", data)
/**
 * 获取档口详情
 * @param id
 * @returns
 */
export const gateDetailApi = (id: string) => get<IGate>("/admin/gate/detail/" + id)
/**
 * 档口下拉列表
 * @returns
 */
export const gateGetGateListApi = () => get<IGate[]>("/admin/gate/getGateList")

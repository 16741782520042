
/**
 * @name: 仓储管理-库存查询
 * @author:
 * @date: 2023-08-04 17:53
 * @description： 仓储管理-库存查询
 * @update: 2023-08-04 17:53
 */
import {Vue, Component} from "vue-property-decorator"
import type {ICrudOption} from "@/types/m-ui-crud"
import {IDistributeRecord} from "@/apis/storage/gateDist/types";
import {deepCopy, exportFile} from "@/utils/common";
import {exportDistributeRecordApi, getDistributeRecordApi} from "@/apis/storage/gateDist";
import {
  exportGateBatchApi,
  exportGateInventoryApi,
  exportInventoryApi,
  getGateBatchInventoryPageApi,
  getGateInventoryPageApi,
  getInventoryInquiryPageApi
} from "@/apis/storage/inventory";
import {gateGetGateListApi} from "@/apis/gate/info";
import {productGetSortListApi} from "@/apis/product/info";
import {IInventoryInquiryPage} from "@/apis/storage/inventory/types";

@Component({})
export default class storageInventory extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: any = []
  tableDataStall: any = []
  tableDataStallLot: any = []
  // 表格总数
  tableTotal = 0
  tableTotalStall = 0
  tableTotalStallLot = 0

  // 查询参数
  queryParam: any = {
    page: 1,
    limit: 10
  }
  // 查询参数
  queryParamStall: any = {
    page: 1,
    limit: 10
  }
  // 查询参数
  queryParamStallLot: any = {
    page: 1,
    limit: 10
  }
  // 库存合计
  totalInventory: string = '0.00'
  // 档口下拉
  gateList: any = []
  // 分类下拉
  classList: any = []
  activeName: string = 'first'
  //

  // 表单参数
  modelForm: any = {}
  // crud配置信息
  crudOption: ICrudOption = {
    menu: false,
    column: [
      {
        label: "商品ID",
        prop: "productId",
        align: 'center',
      },
      {
        label: "商品名称",
        prop: "productName",
        align: 'center',
        search: true,
      },
      {
        label: "商品分类",
        prop: "sortIdList",
        align: "center",
        searchMultiple: true,
        search: true,
        type: 'select',
        dicUrl: "/api/admin/product/getSortList?status=1",
        dicFormatter: (res: any) => ({list: res.data, label: 'sortName', value: 'id'}),
        hide: true
      },
      {
        label: "商品分类",
        prop: "sortName",
        align: 'center',
      },
      {
        label: "库存单位",
        prop: "purchaseUnit",
        align: 'center',
      },
      {
        label: "库存",
        prop: "allStock",
        align: 'center',
      },
    ]
  }

  crudOptionStall: ICrudOption = {
    menu: false,
    column: [
      {
        label: "商品ID",
        prop: "productId",
        align: 'center',
      },


      {
        label: "商品名称",
        prop: "productName",
        align: 'center',
        search: true,
      },
      {
        label: "商品分类",
        prop: "sortIdList",
        align: "center",
        searchMultiple: true,
        search: true,
        type: 'select',
        dicUrl: "/api/admin/product/getSortList?status=1",
        dicFormatter: (res: any) => ({list: res.data, label: 'sortName', value: 'id'}),
        hide: true
      },
      {
        label: "商品分类",
        prop: "sortName",
        align: 'center',
      },
      {
        label: "档口名称",
        prop: "gateName",
        align: 'center',
      },
      {
        label: "档口名称",
        prop: "gateIdList",
        align: "center",
        searchMultiple: true,
        search: true,
        type: 'select',
        dicUrl: "/api/admin/gate/getGateList",
        dicFormatter: (res: any) => ({list: res.data, label: 'gateName', value: 'id'}),
        hide: true
      },
      {
        label: "库存单位",
        prop: "purchaseUnit",
        align: 'center',
      },
      {
        label: "库存",
        prop: "allStock",
        align: 'center',
      },
    ]
  }

  crudOptionStallLot: ICrudOption = {
    menu: false,
    column: [
      {
        label: "商品ID",
        prop: "productId",
        align: 'center',
      },


      {
        label: "商品名称",
        prop: "productName",
        align: 'center',
        search: true,
      },
      {
        label: "商品分类",
        prop: "sortIdList",
        align: "center",
        searchMultiple: true,
        search: true,
        type: 'select',
        dicUrl: "/api/admin/product/getSortList?status=1",
        dicFormatter: (res: any) => ({list: res.data, label: 'sortName', value: 'id'}),
        hide: true
      },
      {
        label: "商品分类",
        prop: "sortName",
        align: 'center',
      },
      {
        label: "档口名称",
        prop: "gateName",
        align: 'center',
      },
      {
        label: "档口名称",
        prop: "gateIdList",
        align: "center",
        searchMultiple: true,
        search: true,
        type: 'select',
        dicUrl: "/api/admin/gate/getGateList",
        dicFormatter: (res: any) => ({list: res.data, label: 'gateName', value: 'id'}),
        hide: true
      },
      {
        label: "批次号",
        prop: "batchNo",
        align: 'center',
      },
      {
        label: "库存单位",
        prop: "purchaseUnit",
        align: 'center',
      },
      {
        label: "库存",
        prop: "allStock",
        align: 'center',
      },
    ]
  }

  handleClick(tab: any, event: any) {
    this.queryParam.gateIdList = []
    this.queryParam.sortIdList = []
    this.queryParam.productName = ''
    this.queryParam.page = 1
    this.queryParam.limit = 10
    this.queryParamStallLot.gateIdList = []
    this.queryParamStallLot.sortIdList = []
    this.queryParamStallLot.productName = ''
    this.queryParamStallLot.page = 1
    this.queryParamStallLot.limit = 10
    this.queryParamStall.gateIdList = []
    this.queryParamStall.sortIdList = []
    this.queryParamStall.productName = ''
    this.queryParamStall.page = 1
    this.queryParamStall.limit = 10
    this.getList()
  }

  /**
   * 导出excel
   */
  exportExcel() {
    if (this.activeName == 'first') {
      const form: IInventoryInquiryPage = deepCopy(this.queryParam);
      form.sortIdList = form.sortIdList || []
      exportInventoryApi(form).then(e => {
        exportFile(e, '库存.xlsx')
      })
    } else if (this.activeName == 'second') {
      const form: IInventoryInquiryPage = deepCopy(this.queryParamStall);
      form.sortIdList = form.sortIdList || []
      form.gateIdList = form.gateIdList || []
      exportGateInventoryApi(form).then(e => {
        exportFile(e, '档口库存.xlsx')
      })
    } else if (this.activeName == 'third') {
      const form: IInventoryInquiryPage = deepCopy(this.queryParamStallLot);
      form.sortIdList = form.sortIdList || []
      form.gateIdList = form.gateIdList || []

      exportGateBatchApi(form).then(e => {
        exportFile(e, '档口批次库存.xlsx')
      })
    }

  }

  /**
   * 获取档口下拉列表
   */
  getGateList() {
    gateGetGateListApi().then(e => {
      this.gateList = e.map((item: any) => {
        return {
          label: item.gateName,
          value: item.id
        }
      });
      // @ts-ignore
      this.$refs.crudRef.updateSearchColumn("gateIdList", {
        dicData: this.gateList
      })
    })
  }

  /**
   * 获取分类下拉列表
   */
  getClassList() {
    productGetSortListApi(1).then(e => {
      this.classList = e.map((item: any) => {
        return {
          label: item.sortName,
          value: item.id
        }
      });
      // @ts-ignore
      this.$refs.crudRef.updateSearchColumn("gateIdList", {
        dicData: this.classList
      })
    })
  }

  getList() {
    if (this.activeName == 'first') {
      // this.getClassList()
      const form: IInventoryInquiryPage = deepCopy(this.queryParam);
      form.sortIdList = form.sortIdList?.toString()
      getInventoryInquiryPageApi(form).then(e => {
        //@ts-ignore
        this.tableData = e.inventoryInquiryVoCommonPage.list;
        this.tableTotal = e.inventoryInquiryVoCommonPage.total
        this.totalInventory = e.totalInventory
      })
    } else if (this.activeName == 'second') {
      // this.getClassList()
      // this.getGateList()
      const form: IInventoryInquiryPage = deepCopy(this.queryParamStall);
      form.sortIdList = form.sortIdList?.toString()
      form.gateIdList = form.gateIdList?.toString()
      getGateInventoryPageApi(form).then(e => {
        //@ts-ignore
        this.tableDataStall = e.inventoryInquiryVoCommonPage.list;
        this.tableTotalStall = e.inventoryInquiryVoCommonPage.total
        this.totalInventory = e.totalInventory
      })
    } else if (this.activeName == 'third') {
      // this.getClassList()
      // this.getGateList()
      const form: IInventoryInquiryPage = deepCopy(this.queryParamStallLot);
      form.sortIdList = form.sortIdList?.toString()
      form.gateIdList = form.gateIdList?.toString()
      getGateBatchInventoryPageApi(form).then(e => {
        //@ts-ignore
        this.tableDataStallLot = e.inventoryInquiryVoCommonPage.list;
        this.tableTotalStallLot = e.inventoryInquiryVoCommonPage.total
        this.totalInventory = e.totalInventory
      })
    }

  }

  created() {
    this.getList()
  }
}

/**
 * @name: 商品管理-商品信息管理接口文件
 * @author:
 * @date: 2023-08-04 17:46
 * @description： 商品管理-商品信息管理接口文件
 * @update: 2023-08-04 17:46
 */
import {get, postJ} from "@/request"
import {IPage, IPageRes} from "@/apis/page";
import {IProductCreate, IProductQuery, RootDataItem} from "@/apis/product/info/types";

/**
 *分页查询
 */
export const productQueryApi = (param:IProductQuery) => get("/admin/product/query",param)

/**
 * 切换状态
 * @param id
 */
export const productUpdateStatusApi = (id: string) => get(`/admin/product/update/status`,{id} )

/**
 * 商品分类下拉
 * @returns
 */
export const productGetSortListApi = (status?: number) => get("/admin/product/getSortList", {status})

/**
 * 商品下拉列表
 * @returns
 */
export const productGetListApi = (status?: number) => get("/admin/product/getList",{status})

/**
 * 商品单位下拉列表
 * @returns
 */
export const productGetUnitListApi = (status?: number) => get("/admin/product/getUnitList",{status})

/**
 * 新增商品
 * @param data
 */
export const productCreateApi = (data: IProductCreate) => postJ<any[]>("/admin/product/create", data)

/**
 * 详情
 * @param id
 */
export const productDetailApi = (id: string) => get("/admin/product/detail/" + id)

/**
 * 获取价格单位绑定列表
 * @param param
 */
export const productGetPriceUnitListApi = (param:any) => get("/admin/product/getPriceUnitList",param)

/**
 * 修改商品
 * @param data
 */
export const productModifyApi = (data: IProductCreate) => postJ<any[]>("/admin/product/modify", data)

/**
 * 获取价格属性列表
 */
export const productGetProductAttrListApi = () => get("/admin/product/getProductAttrList")

/**
 * 编辑售价
 * @param data
 */
export const productEditSellingPriceApi = (data: RootDataItem[]) => postJ("/admin/product/editSellingPrice", data)

/**
 * 查询售价详情
 * @param param
 */
export const productQuerySalesPriceDetailsApi = (param:any) => get("/admin/product/querySalesPriceDetails",param)


/**
 * 导出
 * @param params
 */
export const productExportApi = (params:IProductQuery) => get("/admin/product/export", params,'blob');
